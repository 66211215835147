import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Seo from '../components/Seo'
import ScrollSection from '../components/ScrollSection'
import Spacer from '../components/Spacer'
import Container from '../components/_layouts/Container'
import { Heading2, TextBodyLarge } from '../components/TextStyles'
import Grid from '../components/_layouts/Grid'
import GridItem from '../components/_layouts/GridItem'
import PageTransitionLink from '../components/PageTransitionLink'

const NotFoundPage = () => {
  const {
    site: {
      siteMetadata: { routes },
    },
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          routes {
            home
          }
        }
      }
    }
  `)

  return (
    <>
      <Seo title="404" description="Page not found" />

      <ScrollSection>
        <Container>
          <Spacer size={[150, 240]} />
          <Grid>
            <GridItem tabletP={4} tabletPStart={2}>
              <Heading2>Page not found</Heading2>

              <Spacer size={24} />

              <PageTransitionLink to={routes.home}>
                <TextBodyLarge>Return to Home</TextBodyLarge>
              </PageTransitionLink>
            </GridItem>
          </Grid>
        </Container>
      </ScrollSection>
    </>
  )
}

export default NotFoundPage
